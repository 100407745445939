/* HomePage.module.css */
.container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 50px;
}
.outer {
  background-color: #22272e;
}
/* Common Section Styles */
.sectionTitle {
  font-size: 36px;
  text-align: center;
  margin-bottom: 20px;
  color: #add571;
}

.sectionContent {
  text-align: center;
  font-size: 18px;
  color: #adbac7;
  max-width: 800px;
  margin: 0 auto;
}

/* Hero Section */
.hero {
  position: relative;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;

  box-shadow: rgba(191, 208, 84, 0.25) 0px 30px 60px -12px inset,
    rgba(191, 208, 84, 0.25) 0px 18px 36px -18px inset;
  z-index: 0;
  background-position: center;
  height: 100vh;
  background-attachment: fixed;
  color: white;
  text-align: center;
  background-image: url("https://static.vecteezy.com/system/resources/previews/036/135/742/non_2x/ai-generated-ultra-hd-technology-background-abstract-technology-wallpaper-dark-technology-background-photo.jpg");
}
.w60 {
  width: 50%;
  text-align: start;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  bottom: 0;
  height: 100vh;

  box-shadow: rgba(191, 208, 84, 0.25) 0px 30px 60px -12px inset,
    rgba(191, 208, 84, 0.25) 0px 18px 36px -18px inset;

  width: 80%;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  display: flex;
  align-items: center;
  padding: 50px;
  box-sizing: border-box;
  justify-content: start;
}

.heroTitle {
  font-size: 50px;
  margin-bottom: 16px;
  color: #add571;
}

.heroSubtitle {
  font-size: 32px;
  text-align: center;
  color: #adbac7;
}
.aboutUsSection {
  display: flex;
  align-items: center;
  padding: 50px 0px;
  background-color: #22272e;
}
.heroSection {
  text-align: center;
  padding: 50px 20px;
}

.heading {
  font-size: 3rem;
  margin-bottom: 8px;
  color: #add571;
}

.subHeading {
  font-size: 1.2rem;
  color: #adbac7;
}
.container2 {
  display: flex;
  align-items: center;
  gap: 50px;
  justify-content: space-between;
}
.imageSection {
  flex: 1;
  width: 48%;
  height: 370px;
}

.aboutImage {
  width: 100%;
  height: 370px;
  border-radius: 8px;
}

.textSection {
  height: 370px;
  flex: 1;
  padding: 20px;
  background-color: black;
  width: 48%;
  padding: 20px;
  box-shadow: rgba(130, 150, 102, 0.25) 0px 6px 12px -2px,
    rgba(130, 150, 102, 0.25) 0px 3px 7px -3px;
  border-radius: 8px;
}

.title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #add571;
}

.description {
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.learnMoreButton {
  padding: 15px 45px;
  background-color: #add571;
  color: black;
  border: none;
  border-radius: 4px;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.learnMoreButton:hover {
  background-color: #add571;
}
/* ITSolutionsSection.module.css */
.solutionsSection {
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: center;
  justify-content: space-between;
  background-color: #22272e;
}

.image {
  width: 100%;
  height: 350px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 960px) {
  .heroSection {
    text-align: center;
    padding: 20px;
  }

  .heading {
    font-size: 2rem;
    margin-bottom: 8px;
  }

  .subHeading {
    font-size: 1rem;
    color: #adbac7;
  }
  .heroTitle {
    font-size: 36px !important;
    margin-bottom: 16px;
    color: #add571;
    text-align: center;
  }
  .w60 {
    width: 100% !important;
    text-align: start;
  }
  .aboutImage {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  .heroSubtitle {
    font-size: 24px !important;
    text-align: center;
    color: #adbac7;
  }
  .overlay {
    width: 100% !important;
  }
  .textSection {
    width: 100% !important;
  }
  .imageSection {
    width: 100% !important;
  }
  .container2 {
    flex-wrap: wrap !important;
  }
  .container {
    padding: 20px;
  }
  .solutionsSection {
    display: flex;
    flex-wrap: wrap !important;
    flex-direction: column !important;
    gap: 50px;
    align-items: center;
    justify-content: space-between;
    background-color: #22272e;
  }
}
