/* General Styles */
.aboutPage {
  background-color: #22272e;
  color: #add571;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 50px;
}

.section {
  margin-bottom: 60px;
}

h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

div p {
  color: #adbac7;
  line-height: 1.6;
  font-size: 1.1rem;
}
/* Hero Section */
.heroSection {
  text-align: center;
  padding: 50px 20px;
}

.heading {
  font-size: 3rem;
  margin-bottom: 8px;
}

.subHeading {
  font-size: 1.2rem;
  color: #adbac7;
}

/* Values Section */
.values {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.valueCard {
  background-color: #000000;
  padding: 30px;
  border-radius: 10px;
  width: 30%;
  margin-bottom: 20px;
  text-align: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.valueCard h3 {
  margin-top: 15px;
  margin-bottom: 10px;
}

.icon {
  font-size: 3rem;
  margin-bottom: 10px;
}

/* Team Section */
.team {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.teamMember {
  width: 30%;
  margin-bottom: 20px;
  text-align: center;
}

.teamMember img {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 15px;
}

.teamMember h3 {
  margin-bottom: 5px;
}

.teamMember p {
  font-size: 1rem;
}

/* Process List */
.processList {
  list-style: none;
  padding-left: 0;
}

.processList li {
  background-color: #000000;
  box-shadow: rgba(130, 150, 102, 0.25) 0px 6px 12px -2px,
    rgba(130, 150, 102, 0.25) 0px 3px 7px -3px;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
}

/* Technologies Section */
.techLogos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Testimonials */
.testimonials {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.testimonial {
  background-color: #000000;
  box-shadow: rgba(130, 150, 102, 0.25) 0px 6px 12px -2px,
    rgba(130, 150, 102, 0.25) 0px 3px 7px -3px;
  padding: 30px;
  border-radius: 10px;
  width: 48%;
  margin-bottom: 20px;
}

.testimonial h3 {
  margin-top: 15px;
  font-size: 1rem;
  color: #ccc;
}

/* Partners */
.partners {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.partners img {
  width: 22%;
  margin-bottom: 20px;
}

/* CTA Section */
.ctaSection {
  text-align: center;
  padding: 50px 20px;

  background-color: #000000;
  box-shadow: rgba(130, 150, 102, 0.25) 0px 6px 12px -2px,
    rgba(130, 150, 102, 0.25) 0px 3px 7px -3px;
  border-radius: 10px;
}

.ctaSection h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.ctaSection p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.ctaButton {
  background-color: #add571;
  color: black;
  padding: 15px 30px;
  font-size: 1.1rem;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ctaButton:hover {
  background-color: #add571;
}
li {
  color: #adbac7;
}
li > strong {
  color: #add571;
}
/* Links */
.link {
  color: #add571;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .valueCard,
  .teamMember,
  .testimonial {
    width: 48%;
  }

  .techLogos img,
  .partners img {
    width: 30%;
  }
}

@media (max-width: 768px) {
  .valueCard,
  .teamMember,
  .testimonial {
    width: 100%;
  }

  .techLogos img,
  .partners img {
    width: 45%;
  }

  .heroSection {
    padding: 80px 20px;
  }

  .heading {
    font-size: 2rem;
  }

  .ctaButton {
    padding: 12px 25px;
  }
}

@media (max-width: 480px) {
  .heroSection {
    padding: 60px 20px;
  }

  .heading {
    font-size: 2.5rem;
  }

  .subHeading {
    font-size: 1.2rem;
  }

  h2 {
    font-size: 2rem;
  }

  .ctaSection h2 {
    font-size: 2rem;
  }

  .ctaSection p {
    font-size: 1rem;
  }

  .ctaButton {
    padding: 10px 20px;
    font-size: 1rem;
  }
}
@media screen and (max-width: 960px) {
  .container {
    padding: 20px 20px 40px 20px;
  }
  .ctaSection p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    text-align: justify;
  }

  /* Hero Section */
  .heroSection {
    text-align: center;
    padding: 20px;
  }

  .heading {
    font-size: 2rem;
    margin-bottom: 8px;
  }

  .subHeading {
    font-size: 1rem;
    color: #adbac7;
  }
  h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
}
.icons {
  height: auto;
  width: 60px;
}
.iconouter {
  background-color: white;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
