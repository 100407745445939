.termsContainer {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto 50px auto;
  border-radius: 8px;
  background-color: #000000;
  box-shadow: rgba(130, 150, 102, 0.25) 0px 6px 12px -2px,
    rgba(130, 150, 102, 0.25) 0px 3px 7px -3px;
}

.heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #000; /* Primary color */
}

.paragraph {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #adbac7;
}
.subHeading {
  font-size: 1.8rem;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #add571;
}
.heroSection {
  text-align: center;
  padding: 50px 20px;
}

.heading {
  font-size: 3rem;
  margin-bottom: 8px;
  color: #add571;
}
.outer {
  background-color: #22272e;
}
.subHeading {
  font-size: 1.8rem;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #add571;
}

.list {
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 20px;
}

.link {
  color: #add571;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.contactInfo {
  font-size: 1.2rem;
  color: #add571;
}
@media screen and (max-width: 960px) {
  .heroSection {
    text-align: center;
    padding: 20px;
  }
  .outer {
    background-color: #22272e;
    padding: 20px;
  }
  .heading {
    font-size: 3rem;
    margin-bottom: 8px;
  }

  .subHeading2 {
    font-size: 1.2rem;
    color: #adbac7;
  }
}
