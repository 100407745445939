/* footer.module.css */

.footer {
  background: #22272e;
  color: #adbac7;
  box-shadow: #95b9433d 0px 3px 8px;
  border-top: 0.5px solid #95b9433d;
  padding: 20px 0;
}

.footerContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 70px;
  max-width: 1200px;
  margin: 0px auto;
}

.footerSection {
  flex: 1;
  text-align: center;
  font-size: 16px;
}
.footerSection > p {
  font-size: 16px;
}
.footerSection h4 {
  margin-bottom: 10px;
  font-size: 24px;
}

.footerLinks {
  list-style: none;
  padding: 0;
}

.footerLinks li {
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 16px;
}

.footerLinks a {
  color: #adbac7;
  text-decoration: none;
}

.footerLinks a:hover {
  text-decoration: underline;
}

.footerBottom {
  text-align: center;
  color: #add571;
  margin-top: 20px;
}

@media screen and (max-width: 960px) {
  .footerContainer {
    flex-direction: column;
    text-align: center;
    padding: 20px;
    gap: 10px;
  }

  .footerSection {
    margin: 20px 0;
  }
}
.socialIocns {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
h4 {
  color: #add571;
}
.Iocns {
  height: 24px;
  width: 24px;
  color: #22272e;
}
.IconOuter {
  height: 50px;
  width: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #adbac7;
}
.navbarLogo {
  color: #adbac7;
  height: 70px;
  width: 260px;
}
