.container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 50px;
}
.outer {
  background-color: #22272e;
}
.heroSection {
  text-align: center;
  padding: 50px 20px;
}

.heading {
  font-size: 3rem;
  margin-bottom: 8px;
  color: #add571;
}
.tagflex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.subHeading {
  font-size: 1.2rem;
  color: #adbac7;
}
.ProjectCard {
  background-color: #000000; /* Change to your background color */

  border-radius: 8px;
  padding: 15px;
  box-shadow: rgba(130, 150, 102, 0.25) 0px 6px 12px -2px,
    rgba(130, 150, 102, 0.25) 0px 3px 7px -3px;
  padding: 30px;
}
.Tags {
  font-size: 18px;
  font-weight: 600;
  color: #adbac7;
  cursor: pointer;
}
.projectContent {
  margin-top: 40px;
}
.projectContent h2 {
  margin-bottom: 10px;
  color: #add571;
}
.active {
  background-color: #add571;
  padding: 5px 20px;
  border-radius: 8px;
  cursor: pointer;
  color: black;
}
.cursor {
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  .portfolioContainer {
    padding: 20px 20px 40px 20px;
  }
  .heroSection {
    text-align: center;
    padding: 20px;
  }

  .heading {
    font-size: 2rem;
    margin-bottom: 8px;
  }
  .cursor {
    cursor: pointer;
    width: 100%;
  }
  .subHeading {
    font-size: 1rem;
    color: #adbac7;
  }
  .tagflex {
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
  }
  .Tags {
    text-align: center;
  }
}
.ProjectConatiner {
  padding: 20px;
  background-color: #22272e;
  margin-top: 30px;
  border-radius: 8px;
}
.ProjectConatiner > a > p {
  color: #add571;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
}
.ProjectConatiner > a {
  text-decoration: none;
  text-transform: none;
  color: #add571 !important;
}
