/* navBar.module.css */

.navbar {
  background: #22272e;
  height: 80px;
  display: flex;
  align-items: center;
  margin: 0px auto;
  max-width: 1200px;
  font-size: 1.2rem;
}
.outer {
  border-bottom: 0.5px solid #95b9433d;
  background: #22272e;
}
.navbarContainer {
  display: flex;
  gap: 200px;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
}

.navbarLogo {
  color: #adbac7;
  height: 50px;
  margin-top: 15px;
  width: 210px;
}

.menuIcon {
  display: none;
}

.navMenu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-top: 60px;
}

.navLinks {
  color: #adbac7;
  text-decoration: none;
  padding: 0.5rem 1rem;
  position: relative;
}

.navItem {
  height: 80px;
}

.activeLink::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 8px;
  background-color: #add571;
  border-radius: 4px;
}

@media screen and (max-width: 960px) {
  .navbar {
    background: #22272e;
    height: 80px;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    padding: 20px;
  }
  .menuIcon {
    display: block;
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 1000 !important;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #add571;
  }

  .navMenu {
    display: none;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    background: #22272e;
  }

  .navMenu.active {
    display: flex;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .navItem {
    height: 60px;
  }
  .barbtn {
    color: #adbac7;
  }
  .menulist:hover {
    background-color: #22272e;
    color: #adbac7;
    border-radius: 10px;
  }
}

.barbtn {
  display: none;
}

.mobileMenu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1000;
  transition: all 0.3s ease;
  overflow-y: auto;
  width: 75%;
}

.mobileMenu.open {
  display: block;
}

.menuHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
  background-color: #22272e;
  color: white;
}

.Headerlogo {
  height: 40px;
}

.Headerclose {
  font-size: 1.8rem;
  cursor: pointer;
}

.mobileMenuContent {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.menulist {
  padding: 10px 0;
  text-decoration: none;
  color: #22272e;
  font-size: 1.2rem;
  font-weight: 600;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.menulist:hover {
  color: white;
  padding: 10px;
}
.menulist {
  padding: 10px;
}
