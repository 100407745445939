.contactPage {
  background-color: #22272e;
  color: #add571;
}

.heroSection {
  text-align: center;
  padding: 50px 20px;
}

.heading {
  font-size: 3rem;
  margin-bottom: 8px;
}

.subHeading {
  font-size: 1.2rem;
  color: #adbac7;
}
.formGroup > input::placeholder {
  font-family: "Poppins", sans-serif;
}
.formGroup > textarea::placeholder {
  font-family: "Poppins", sans-serif;
}
.formGroup > input,
textarea {
  font-family: "Poppins", sans-serif;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}

.contactFormSection {
  background-color: #000000;
  box-shadow: rgba(130, 150, 102, 0.25) 0px 6px 12px -2px,
    rgba(130, 150, 102, 0.25) 0px 3px 7px -3px;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 40px;
}

.formHeading {
  color: #add571;
  text-align: center;
}

.contactForm {
  display: flex;
  flex-direction: column;
}

.formGroup {
  margin-bottom: 20px;
}

input,
textarea {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #add571;
  background-color: #22272e;
  color: #add571;
  font-size: 1rem;
}
* {
  padding: 0px;
  margin: 0%;
  box-sizing: border-box;
}
.submitButton {
  background-color: #add571;
  color: black;
  padding: 15px;
  font-size: 1.1rem;
  border: none;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease;
}

.submitButton:hover {
  background-color: #add571;
}

.contactDetails {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.info {
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 1.2rem;
}

.icon {
  font-size: 2rem;
  margin-right: 10px;
  color: #add571;
}

.map {
  margin-top: 40px;
  background-color: #000000;
  box-shadow: rgba(130, 150, 102, 0.25) 0px 6px 12px -2px,
    rgba(130, 150, 102, 0.25) 0px 3px 7px -3px;
  padding: 20px;
  border-radius: 16px;
}
@media screen and (max-width: 960px) {
  .container {
    padding: 20px 20px 40px 20px;
  }
  .heroSection {
    text-align: center;
    padding: 20px;
  }

  .heading {
    font-size: 2rem;
    margin-bottom: 8px;
  }

  .subHeading {
    font-size: 1rem;
    color: #adbac7;
  }
  .info {
    display: flex;
    align-items: center;
    margin: 20px 0;
    font-size: 1.2rem;
    width: 100%;
  }
}
