.servicesContainer {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 50px;
}
.servicesouter {
  background-color: #22272e;
}

.heroSection {
  text-align: center;
  padding: 50px 20px;
}

.heading {
  font-size: 3rem;
  margin-bottom: 8px;
  color: #add571;
}

.subHeading {
  font-size: 1.2rem;
  color: #adbac7;
}
.servicesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.serviceCard {
  background-color: #000000; /* Background color */
  border-radius: 8px;
  padding: 15px;
  box-shadow: rgba(130, 150, 102, 0.25) 0px 6px 12px -2px,
    rgba(130, 150, 102, 0.25) 0px 3px 7px -3px;
  transition: transform 0.2s;
}

.serviceCard:hover {
  transform: scale(1.05);
}

.serviceImage {
  width: 100%;
  height: 200px;
  border-radius: 8px;
}

.serviceTitle {
  font-size: 1.5rem;
  margin: 10px 0;
  color: #add571;
}

.serviceDescription {
  font-size: 1rem;
  color: #adbac7;
}
@media screen and (max-width: 960px) {
  .servicesContainer {
    padding: 20px 20px 40px 20px;
  }
  .heroSection {
    text-align: center;
    padding: 20px;
  }

  .heading {
    font-size: 2rem;
    margin-bottom: 8px;
  }

  .subHeading {
    font-size: 1rem;
    color: #adbac7;
  }
}
@media (min-width: 320px) and (max-width: 5200px) {
  .serviceImage {
    width: 100%;
    height: 250px;
    border-radius: 8px;
  }
}
